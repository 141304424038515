<template>
  <section>
    <new-contact-create v-model="contact"></new-contact-create>
    <a-row type="flex" class="ml-5">
      <a-button class="bg-danger text-white" @click="deleteContact"
        >O'chirish</a-button
      >
      <a-button class="bg-warning text-white ml-2" @click="editContact"
        >O'zgartirish</a-button
      >
    </a-row>
    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos barcha malumotni kiriting
      </p>
    </modal>
  </section>
</template>

<script>
import newContactCreate from '../../components/Contacts/ContactCreate.vue';

import { mapActions } from 'vuex';
export default {
  props: ['contactId'],
  components: {
    newContactCreate,
  },

  data() {
    return {
      inValid: false,
      contact: {
        phone_number: '',
        email: '',
        message: '',
      },
    };
  },
  methods: {
    ...mapActions({
      getOneContact: 'contact/getOneContact',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },
    editContact() {},

    deleteContact() {},
  },

  created() {
    this.getOneContact(this.contactId)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        this.$notification['error']({
          message: 'Xatolik',
          description: 'Serverda Xatolik' + error.message,
        });
        setTimeout(() => {
          this.$router.push({ name: 'Contact' });
        }, 1000);
      });
  },
};
</script>
