<template>
  <section class="container">
    <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
      <a-form-item label="Email">
        <a-input v-model="value.email" :value="value.email" />
      </a-form-item>
      <a-form-item label="Telefon raqam">
        <a-input v-model="value.phone_number" :value="value.phone_number" />
      </a-form-item>
      <a-form-item label="Xabar">
        <a-input
          type="textarea"
          rows="4"
          v-model="value.message"
          :value="value.message"
        />
      </a-form-item>
    </a-form>
  </section>
</template>

<script>
export default {
  props: {
    value: Object,
    default: () => ({}),
  },

  model: {
    prop: 'value',
    event: 'change',
  },
};
</script>
